import { Row } from "antd"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 98vh;
`

const HeaderImage = styled.img`
  max-width: 100%
`

const CurpInputContainer = styled(Row)`
  padding: 20px 5%;
  width: 100%;
`

const ResultContainer = styled(Row)`
  padding: 0px 5%;
  flex-grow: 1;
`

const CenterDiv = styled.div`
  text-align: center;
`

const ParagraphContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`

const FooterContainer = styled(Row)`
  padding: 0px 5%;
`

const TableContainer = styled.div`
  padding: 0px 5%;
`

export {
  Container,
  HeaderImage,
  CurpInputContainer,
  ResultContainer,
  CenterDiv,
  ParagraphContainer,
  FooterContainer,
  TableContainer
}
