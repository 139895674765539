import { FC } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Text.styles";
import { GizmoDisplay } from "../../utils";
import { Typography } from "antd";

const { Text: AntText } = Typography;

type TextProps = {
  gizmo: Gizmo;
};

const Text: FC<TextProps> = ({ gizmo }) => {
  const { config, features } = useGizmo({ gizmo });
  const textProps: any = {};
  if (
    config.ops?.text?.type === "danger" ||
    config.ops?.text?.type === "warning" ||
    config.ops?.text?.type === "secondary" ||
    config.ops?.text?.type === "success"
  ) {
    textProps["type"] = config.ops?.text?.type;
  }

  return (
    <GizmoDisplay features={features}>
      <S.TextDiv
        id={`text-${gizmo.getConfig().fid}`}
        columns={gizmo.getColumns()}
      >
        <AntText {...textProps}>
          <span dangerouslySetInnerHTML={{ __html: features.label }} />
        </AntText>
      </S.TextDiv>
    </GizmoDisplay>
  );
};

export default Text;
